.pmw-main-wrapper {
  #local-services {
    .map-box {
      position: relative;
      img {
        width: 100%;
      }
      a {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .audio-toggle-wrapper {
    justify-content: space-evenly;
  }
}

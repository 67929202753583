@import "../../assets/sass/_color.scss";

.pmw-scrolltop-btn-wrapper {
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 20000;
  transition: opacity 1s;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  .pmw-scrolltop-btn {
    border-radius: 100%;
    border: 1.8px solid $orange-btn;
    height: 35px;
    width: 35px;
    padding: 2px;
    font-size: 21px;
    color: $orange-btn;
    background-color: white;
    i {
      position: relative;
      top: -2px;
    }
    &:hover {
      background-color: $light-orange;
      color: white;
      border: 1.8px solid white;
    }
  }
}

@import "../../assets/sass/_color.scss";

.pmw-home-wrapper {
  .pmw-home-r1 {
    background-color: $home-yellow;
    img {
      margin: 0 auto;
    }
  }
  .pmw-home-r2 {
    text-align: center;
  }
  .pmw-home-r3 {
    text-align: center;
    .link-box-wrapper {
      margin-bottom: 15px;
      .link-box {
        display: block;
        height: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        text-decoration: none;
        .icon {
          width: 150px;
          margin-bottom: 10px;
        }
        p {
          color: $navy;
          font-weight: 600;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .pmw-home-r4 {
    text-align: center;
  }
  .pmw-home-r5 {
    text-align: center;
  }
}

@import "../../../../assets/sass/_color.scss";

.pmw-article4-wrapper {
  .pmw-article-hero-row {
    background-color: $light-pink2;
  }
  .hero-img {
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .callout {
    font-weight: 600;
    font-style: italic;
    font-size: 1.125rem;
    margin: 0 auto;
    width: 85%;
    text-align: center;
  }
  hr {
    border-top: 2px solid $navy;
    opacity: 1;
    margin: 1.25rem 0;
  }
  .num-box {
    background-color: $pink-btn;
    text-align: center;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .txt-box {
    background-color: $light-pink;
    color: $navy;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .link-box {
    display: flex;
    align-items: center;

    a {
      color: $navy;
      text-decoration: none;
    }
  }
}

.show-helpers {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  padding: 1rem;
  background-color: #003366;
  color: white;

  &.on {
    background-color: white;
    color: blue;
  }
}

@import "../../assets/sass/_color.scss";

.pmw-articlepreview-r1 {
  background-color: white;
  margin: 0 auto;
  border: solid 1px $navy;
  .link-box {
    display: flex;
    a {
      margin-left: auto;
      text-decoration: none;
      color: $navy;
      font-size: 0.875rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

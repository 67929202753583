@import "../../assets/sass/_color.scss";

.pmw-mainheader-r1 {
  background-color: white;
  .pmw-mh-col {
    display: flex;
    align-items: center;
    h2 {
      margin-bottom: 0;
      line-height: 1.5;
    }
  }
}
.pmw-mainheader-r2 {
  text-align: center;
  border-top: 1px solid $navy;
  border-bottom: 1px solid $navy;
  .col {
    border-right: 1px solid $navy;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    p {
      margin-bottom: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .col:last-child {
    border-right: none;
  }
}
.pmw-mainheader-r3 {
  text-align: center;

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .main-hero-img {
      width: 80%;
      margin: 0 auto;
    }
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
    .main-hero-img {
      width: 70%;
      margin: 0 auto;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {
    .main-hero-img {
      max-height: 400px;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .main-hero-img {
      max-height: 500px;
    }
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .main-hero-img {
      max-height: 600px;
    }
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) {
    .main-hero-img {
      max-height: 600px;
    }
  }
}

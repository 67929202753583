// Colors
$green: #0d5e5f;
$light-green: rgba(18, 96, 134, 0.1);
$light-green2: #e7eff3;
$green-btn: #126086;
$teal: #acdcda;
$light-teal: #eef8f7;
$dark-teal: #57b6b2;
$dark-teal2: #54b6b2;
$light-purple: rgba(83, 85, 137, 0.1);
$light-purple2: #eeeef3;
$purple-btn: #535589;
$pink: #f4436c;
$light-pink: rgba(239, 68, 109, 0.1);
$light-pink2: #fdecf0;
$pink-btn: #ef446d;
$navy: #0a2340;
$lime: rgba(162, 206, 94, 0.8);
$dark-lime: #5fa844;
$yellow: #f4b21b;
$light-yellow: rgba(216, 130, 45, 0.1);
$light-yellow2: #fbf3ea;
$home-yellow: #fff9e1;
$orange-btn: #d8822d;
$light-orange: #f2d5b9;
$medium-orange: #e4aa71;
$cream: #fcf8f2;
$border-gray: rgba(0, 0, 0, 0.3);
$border-dark-gray: #707070;
$skyblue: #cbe8ed;
$grass-green: #a2ce5d;

.pmw-milestones-wrapper {
  .hop-wrapper {
    position: relative;
    background-image: url("../../../../assets/img/milestones/Chalk-1.png"),
      url("../../../../assets/img/milestones/Chalk-2.png"),
      url("../../../../assets/img/milestones/Chalk-3.png"),
      url("../../../../assets/img/milestones/Chalk-4.png"),
      url("../../../../assets/img/milestones/Chalk-5.png"),
      url("../../../../assets/img/milestones/Chalk-6.png"),
      url("../../../../assets/img/milestones/Chalk-7.png");
    background-repeat: no-repeat;

    background-size: auto;

    // X-Small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
      background-position: top 5% left 4%, top 17% right 4%, top 30% left 3%,
        top 50% left 70%, top 60% right 2%, top 80% left 5%, top 95% right 10%;
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767.98px) {
      background-position: top 5% left 4%, top 17% right 4%, top 30% left 3%,
        top 50% left 70%, top 60% right 2%, top 80% left 5%, top 95% right 10%;
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) and (max-width: 991.98px) {
      background-position: top 5% left 20%, top 17% right 20%, top 30% left 15%,
        top 50% left 35%, top 60% right 25%, top 80% left 15%, top 95% right 30%;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) and (max-width: 1199.98px) {
      background-position: top 5% left 30%, top 17% right 30%, top 30% left 25%,
        top 50% left 35%, top 60% right 25%, top 80% left 25%, top 95% right 40%;
    }

    // X-Large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) and (max-width: 1399.98px) {
      background-position: top 5% left 30%, top 17% right 30%, top 30% left 25%,
        top 50% left 35%, top 60% right 25%, top 80% left 25%, top 95% right 40%;
    }

    // XX-Large devices (larger desktops, 1400px and up)
    @media (min-width: 1400px) {
      background-position: top 5% left 35%, top 17% right 35%, top 30% left 30%,
        top 50% left 43%, top 60% right 35%, top 80% left 35%, top 95% right 40%;
    }

    .hop-col {
      height: 150px;
      width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .hop-r1 {
      .hop-top {
        width: 300px;
        height: 150px;
        background-image: url("../../../../assets/img/milestones/Hopscotch-Top.png");
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;

        &-img {
          position: relative;
          top: 65px;
          width: 70%;
        }
      }
      .hop-top-spanish {
        width: 300px;
        height: 150px;
        background-image: url("../../../../assets/img/milestones/Hopscotch-Top.png");
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;

        &-img {
          position: relative;
          top: 75px;
          width: 65%;
        }
      }
    }
    .hop-r2 {
      .col1 {
        background-image: url("../../../../assets/img/milestones/Yellow-Horizontal-Line.png"),
          url("../../../../assets/img/milestones/Yellow-Horizontal-Line.png"),
          url("../../../../assets/img/milestones/Yellow-Vertical-Line.png"),
          url("../../../../assets/img/milestones/Yellow-Vertical-Line.png");
        background-position: top, bottom, left, right;
        background-repeat: no-repeat;
      }
      .col2 {
        background-image: url("../../../../assets/img/milestones/Yellow-Horizontal-Line.png"),
          url("../../../../assets/img/milestones/Yellow-Horizontal-Line.png"),
          url("../../../../assets/img/milestones/Yellow-Vertical-Line.png");
        background-position: top, bottom, right;
        background-repeat: no-repeat;
      }
    }
    .hop-r3 {
      .col1 {
        background-image: url("../../../../assets/img/milestones/Green-Vertical-Line.png"),
          url("../../../../assets/img/milestones/Green-Vertical-Line.png");
        background-position: left, right;
        background-repeat: no-repeat;
      }
    }
    .hop-r4 {
      .col1 {
        background-image: url("../../../../assets/img/milestones/Blue-Horizontal_Line.png"),
          url("../../../../assets/img/milestones/Blue-Horizontal_Line.png"),
          url("../../../../assets/img/milestones/Blue-Vertical-Line.png"),
          url("../../../../assets/img/milestones/Blue-Vertical-Line.png");
        background-position: top, bottom, left, right;
        background-repeat: no-repeat;
      }
      .col2 {
        background-image: url("../../../../assets/img/milestones/Blue-Horizontal_Line.png"),
          url("../../../../assets/img/milestones/Blue-Horizontal_Line.png"),
          url("../../../../assets/img/milestones/Blue-Vertical-Line.png");
        background-position: top, bottom, right;
        background-repeat: no-repeat;
      }
    }
    .hop-r5 {
      .col1 {
        background-image: url("../../../../assets/img/milestones/Orange-Vertical_Line.png"),
          url("../../../../assets/img/milestones/Orange-Vertical_Line.png");
        background-position: left, right;
        background-repeat: no-repeat;
      }
    }
    .hop-r6 {
      .col1 {
        background-image: url("../../../../assets/img/milestones/Pink-Horizontal_Line.png"),
          url("../../../../assets/img/milestones/Pink-Horizontal_Line.png"),
          url("../../../../assets/img/milestones/Pink-Vertical_Line.png"),
          url("../../../../assets/img/milestones/Pink-Vertical_Line.png");
        background-position: top, bottom, left, right;
        background-repeat: no-repeat;
      }
      .col2 {
        background-image: url("../../../../assets/img/milestones/Pink-Horizontal_Line.png"),
          url("../../../../assets/img/milestones/Pink-Horizontal_Line.png"),
          url("../../../../assets/img/milestones/Pink-Vertical_Line.png");
        background-position: top, bottom, right;
        background-repeat: no-repeat;
      }
    }
    .hop-r7 {
      .col1 {
        background-image: url("../../../../assets/img/milestones/Yellow-Vertical-Line.png"),
          url("../../../../assets/img/milestones/Yellow-Vertical-Line.png");
        background-position: left, right;
        background-repeat: no-repeat;
      }
    }
    .hop-r8 {
      .col1 {
        background-image: url("../../../../assets/img/milestones/Green-Horizontal-Line.png"),
          url("../../../../assets/img/milestones/Green-Horizontal-Line.png"),
          url("../../../../assets/img/milestones/Green-Vertical-Line.png"),
          url("../../../../assets/img/milestones/Green-Vertical-Line.png");
        background-position: top, bottom, left, right;
        background-repeat: no-repeat;
      }
      .col2 {
        background-image: url("../../../../assets/img/milestones/Green-Horizontal-Line.png"),
          url("../../../../assets/img/milestones/Green-Horizontal-Line.png"),
          url("../../../../assets/img/milestones/Green-Vertical-Line.png");
        background-position: top, bottom, right;
        background-repeat: no-repeat;
      }
    }
    .hop-r9 {
      .col1 {
        background-image: url("../../../../assets/img/milestones/Blue-Horizontal_Line.png"),
          url("../../../../assets/img/milestones/Blue-Vertical-Line.png"),
          url("../../../../assets/img/milestones/Blue-Vertical-Line.png");
        background-position: bottom, left, right;
        background-repeat: no-repeat;
      }
    }
  }
  .hop-wrapper-spanish .hop-col a {
    display: flex;
    justify-content: center;
    img {
      width: 85%;
    }
  }
}

@import "../../assets/sass/_color.scss";

.pmw-menu-wrapper {
  .menu-item {
    display: flex;
    align-items: center;
    color: $navy;
    img {
      margin-right: 0.75rem;
      width: 75px;
    }
    text-decoration: none;
  }
  .chevron-wrapper {
    margin-left: auto;
    padding: 4px 10px;
    width: 52px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .fa-chevron-right {
      font-size: 1.5rem;
    }
  }
}

@import "../../assets/sass/_color.scss";

.pmw-call-btn-wrapper {
  position: relative;
  .pmw-call-btn {
    background-color: $lime;
    border-radius: 9px;
    height: 66px;
    width: 66px;
    padding: 2px;
    text-align: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 20000;
    a {
      color: white;
      text-decoration: none;
      font-size: 28px;
    }
    p {
      color: white;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 13px;
    }
  }
}

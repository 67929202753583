@import "../../assets/sass/_color.scss";

.pmw-header {
  transition: background 0.7s ease-in-out;
  .row {
    height: 78px;
  }

  .left-col,
  .right-col {
    display: inline-flex;
    align-items: center;
  }

  .menu-toggler {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: auto;
    // display: flex;
    // align-items: center;
    text-decoration: none;
    font-size: 36px;
    .fa-bars,
    .fa-home {
      color: white;
      font-size: 36px;
    }
  }
  &.white-bg {
    border-top: 0.5px solid $orange-btn;
    border-bottom: 0.5px solid $orange-btn;
    .fa-bars,
    .fa-home {
      color: $orange-btn;
    }
  }
}

.is-hidden {
  opacity: 0;
  // transition: transform 0.7s, opacity 0.2s;
  pointer-events: none;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.pmw-header-logo {
  max-width: 100%;
  height: auto;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .pmw-header-logo {
    max-width: 350px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .pmw-header-logo {
    max-width: 350px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .pmw-header-logo {
    max-width: 350px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .pmw-header-logo {
    max-width: 350px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .pmw-header-logo {
    max-width: 350px;
  }
}

.pmw-main-wrapper {
  .hop-box {
    position: relative;
    img {
      width: 100%;
    }
    a {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.step {
  height: 500px;
  margin-bottom: 16px;
  color: white;
  width: 100px;
  word-wrap: wrap;
  background-color: black;
  opacity: 0;
  z-index: 100;

  &.show {
    opacity: 60%;
  }
}

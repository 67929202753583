@import "../../../../assets/sass/_color.scss";

.pmw-infographic-wrapper {
  #infographic {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .infographic-p1,
  .infographic-p2 {
    font-size: 1.125rem;
    margin-bottom: 0;
  }
  .infographic-p1 {
    // X-Small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
      width: 85%;
      margin: 0 auto;
    }
  }

  .pmw-scrolldown-btn {
    background-color: transparent;
    border: none;
    font-size: 48px;
    color: $navy;
  }

  .sticky-container {
    position: sticky;
    top: 0%;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
    height: var(--window-height);
    .part1 {
      height: var(--window-height);
    }
  }

  .part1 {
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;

    &__spacer {
      margin-top: -27rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 13.4rem;
      border-right: 1.5rem solid #c35322;
      transform: translateX(0.27rem);
    }

    .floor {
      width: 100%;
      text-align: center;

      img {
        width: var(--room-img-width);
      }

      &-container {
        width: 100%;
        display: flex;
        height: var(--window-height-top);
        flex-direction: column;
        overflow-y: hidden;
      }

      &-loc0 {
        top: 0%;
        transform: translateY(0%);
        transition-duration: 2000ms;
      }
      &-loc1 {
        top: -16.6%;
        transform: translateY(-16.6%);
        transition-duration: 2000ms;
      }
      &-loc2 {
        top: -33.2%;
        transform: translateY(-33.2%);
        transition-duration: 2000ms;
      }
      &-loc3 {
        top: -49.8%;
        transform: translateY(-49.8%);
        transition-duration: 2000ms;
      }
      &-loc4 {
        top: -66.4%;
        transform: translateY(-66.4%);
        transition-duration: 2000ms;
      }
      &-loc5 {
        top: -83%;
        transform: translateY(-83%);
        transition-duration: 2000ms;
      }
    }

    .desc {
      text-align: left;
      color: white;
      transition: opacity 500ms;
      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: top;
        // height: var(--window-height-bottom);
        padding-top: 20px;
        flex: 1;
      }
      &-1 {
        // max-width: 750px;
        position: absolute;
        padding-left: 15px;
        padding-right: 15px;
      }
      &-2,
      &-3,
      &-4,
      &-5,
      &-6 {
        // max-width: 750px;
        position: absolute;
        opacity: 0;
        padding-left: 15px;
        padding-right: 15px;
      }
      &-title {
        font-size: var(--desc-title);
        font-weight: 600;
        margin-bottom: 0.25rem;
      }
      &-bullet {
        font-size: var(--desc-bullet);
        padding-left: 1.2rem;
        margin-bottom: 0;
      }
    }
  }
}

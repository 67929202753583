@import "../../assets/sass/_color.scss";

.audio-player {
  position: relative;
  width: 320px;
  border: 2px solid $dark-teal;
  padding: 8px 10px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-pause-btn-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 9%;
  height: 32px;
}
.volume-btn-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 32px;
}
.progress-bar-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 32px;
  #progress-bar {
    width: 60%;
  }
  .time {
    font-size: 14px;
    // display: flex;
    // justify-content: center;
    &-1 {
      // margin-right: 8px;
      width: 20%;
    }
    &-2 {
      // margin-left: 8px;
      width: 20%;
    }
  }
}
.volume-bar-wrapper {
  display: flex;
  height: 28px;
  align-items: center;
  background-color: rgba(97, 97, 97);
  border-radius: 7px;
  padding: 0px 6px;
  position: absolute;
  top: -75px;
  right: -28px;
  transform: rotate(270deg);
  // width: 15px;
  // height: 15px;
  input {
    background-color: rgba(97, 97, 97);
    width: 100px;
    height: 28px;
  }
}

.play-pause-btn,
.volume-btn {
  display: flex;
  padding: 0;
  background-color: transparent;
  border: none;
  color: $dark-teal;

  .fa-pause,
  .fa-play {
    font-size: 18px;
  }
  .fa-volume,
  .fa-volume-mute {
    font-size: 20px;
  }
}

input[type="range"] {
  -webkit-appearance: none;
  // margin: 12px 0;
  // width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  // width: 100%;
  height: 6px;
  cursor: pointer;
  // box-shadow: 1px 1px 1px grey, 0px 0px 1px grey;
  background: $dark-teal;
  // border-radius: 0px;
  border: 0.2px solid $dark-teal2;
}
input[type="range"]::-webkit-slider-thumb {
  // box-shadow: 1px 1px 1px $light-teal, 0px 0px 1px $light-teal;
  border: 1px solid $dark-teal2;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: $dark-teal2;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: $dark-teal;
}
input[type="range"]::-moz-range-track {
  // width: 100%;
  height: 6px;
  cursor: pointer;
  // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: $dark-teal;
  // border-radius: 2px;
  border: 0.2px solid $dark-teal2;
}
input[type="range"]::-moz-range-thumb {
  // box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid $dark-teal2;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: $dark-teal2;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  // width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-fill-upper {
  background: #57b6b2;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: blue;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #57b6b2;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #367ebd;
}

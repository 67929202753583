@import "../../assets/sass/_color.scss";

.modal {
  .modal-header {
    padding: 0.5rem 0.8rem;
    background-color: $orange-btn;
    .modal-title {
      font-size: 0.5rem;
      .survey-modal-logo {
        width: 250px;
      }
    }
    .btn-close {
      opacity: 1;
      width: 0.5rem;
      height: 0.5rem;
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
        center/0.7em auto no-repeat;
    }
  }
  .modal-body {
    p {
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
      a {
        color: $green;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.language-bar {
  display: flex;
  justify-content: end;
  .lang-btn {
    font-size: 12px;
    padding: 5px 18px;
    background: transparent;
    border: none;
  }
}

.pmw-skiplinks-wrapper {
  width: 100%;

  .skip-link {
    margin: 0px;
    font-weight: 600;
    padding: 5px 10px;
    color: white;
    position: absolute;
    top: -1000px;
    left: -1000px;
    height: 1px;
    width: 1px;
    background-color: #722278;

    &:focus {
      position: static !important;
      top: 0px !important;
      left: 0 !important;
      height: 100% !important;
      width: 100% !important;
      display: block;
    }
  }
}

@import "./_color.scss";
@import "./animations";

// Poppins Font
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#main-content {
  width: 100%;
  height: 100%;
  // max-width: 767px;
  // margin: 0 auto;
  padding: 0px;
  color: $navy;
  // overflow-x: hidden;
  // margin-top: 78px;
}

.pmw-limit-width {
  // max-width: 767px;
  max-width: 991px;
  margin-left: auto;
  margin-right: auto;
}

.pmw-100w {
  // max-width: 100%;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;

  @media screen and (min-width: 0px) {
    font-size: 10px;
  }

  @media screen and (min-width: 320px) {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }

  @media screen and (min-width: 1000px) {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}

body {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-weight: 600;
  font-size: 2.5rem;
}
h2 {
  font-weight: 600;
  font-size: 1.75rem;
}
h3 {
  font-weight: 600;
  font-size: 1.25rem;
}
p {
  font-size: 1rem;
}

.small-txt {
  font-size: 0.8rem;
}

.white-border-bottom {
  border-bottom: white 1px solid;
}
.navy-border-bottom {
  border-bottom: $navy 1px solid;
}

.sect-p50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.sect-p25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.sect-p40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pmw-pt25 {
  padding-top: 25px;
}
.pmw-pt50 {
  padding-top: 50px;
}
.pmw-pb25 {
  padding-bottom: 25px;
}
.pmw-pb50 {
  padding-bottom: 50px;
}
.pmw-pb80 {
  padding-bottom: 80px;
}

.pmw-mt25 {
  margin-top: 25px;
}
.pmw-mt50 {
  margin-top: 50px;
}
.pmw-mb5 {
  margin-bottom: 5px;
}
.pmw-mb10 {
  margin-bottom: 10px;
}
.pmw-mb15 {
  margin-bottom: 15px;
}
.pmw-mb25 {
  margin-bottom: 25px;
}
.pmw-mb50 {
  margin-bottom: 50px;
}
.pmw-mb75 {
  margin-bottom: 75px;
}

.pmw-w80 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.light-green-bg {
  background-color: $light-green;
}
.light-green-bg2 {
  background-color: $light-green2;
}

.light-purple-bg {
  background-color: $light-purple;
}
.light-purple-bg2 {
  background-color: $light-purple2;
}

.light-pink-bg {
  background-color: $light-pink;
}
.light-pink-bg2 {
  background-color: $light-pink2;
}

.light-yellow-bg {
  background-color: $light-yellow;
}
.light-yellow-bg2 {
  background-color: $light-yellow2;
}
.white-bg {
  background-color: white;
}
.teal-bg {
  background-color: $teal;
}
.light-teal-bg {
  background-color: $light-teal;
}
.dark-teal-bg {
  background-color: $dark-teal;
}
.green-bg {
  background-color: $green;
}
.green-btn-bg {
  background-color: $green-btn;
}
.purple-btn-bg {
  background-color: $purple-btn;
}
.orange-btn-bg {
  background-color: $orange-btn;
}
.light-orange-bg {
  background-color: $light-orange;
}
.medium-orange-bg {
  background-color: $medium-orange;
}
.pink-btn-bg {
  background-color: $pink-btn;
}
.navy-bg {
  background-color: $navy;
}
.skyblue-bg {
  background-color: $skyblue;
  &.navy-bb {
    border-bottom: 15px solid $navy;
  }
}
.grass-green-bg {
  background-color: $grass-green;
}
.dark-lime-bg {
  background-color: $dark-lime;
}

.white-txt {
  color: white;
}
.green-txt {
  color: $green;
}
.green-btn-txt {
  color: $green-btn;
}
.purple-btn-txt {
  color: $purple-btn;
}
.orange-btn-txt {
  color: $orange-btn;
}
.pink-btn-txt {
  color: $pink-btn;
}
.dark-teal2-txt {
  color: $dark-teal2;
}
.navy-txt {
  color: $navy;
}
.dark-teal-txt {
  color: $dark-teal;
}
.dark-lime-txt {
  color: $dark-lime;
}
.grass-green-txt {
  color: $grass-green;
}

// Accordion
#resourcepageAccordion,
#resourceAccordion,
#aboutAccordion {
  .accordion-item {
    border-radius: 0;
    border-color: $orange-btn;
    border-width: 1px;
    color: $navy;
    &.green {
      border-color: $green-btn;
    }
    &.purple {
      border-color: $purple-btn;
    }
    &.orange {
      border-color: $orange-btn;
    }
    &.pink {
      border-color: $pink-btn;
    }
    &.dark-teal {
      border-color: $dark-teal;
    }
    &.grass-green {
      border-color: $grass-green;
    }
  }
  .accordion-header {
    border-radius: 0;
  }
  .accordion-button {
    border-radius: 0;
    p {
      padding-right: 5px;
      color: $navy;
    }
  }
  .accordion-button:not(.collapsed) {
    color: white;
    font-weight: 600;
    background-color: $orange-btn;

    &.green {
      background-color: $green-btn;
    }
    &.purple {
      background-color: $purple-btn;
    }
    &.orange {
      background-color: $orange-btn;
    }
    &.pink {
      background-color: $pink-btn;
    }
    &.dark-teal {
      background-color: $dark-teal;
    }
    &.grass-green {
      background-color: $grass-green;
    }
    p {
      color: white;
    }
  }

  .accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(216, 130, 45, 1)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }
  .accordion-button.green.collapsed::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(18, 96, 134, 1)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }
  .accordion-button.purple.collapsed::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(83, 85, 137, 1)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }
  .accordion-button.orange.collapsed::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(216, 130, 45, 1)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }
  .accordion-button.pink.collapsed::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(239, 68, 109, 1)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }
  .accordion-button.dark-teal.collapsed::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(87, 182, 178, 1)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }
  .accordion-button.grass-green.collapsed::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(95, 168, 68, 1)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }

  #aboutAccordionHeadingOne button {
    border-top: 1px solid $orange-btn;
  }

  #resourcepageAccordionHeadingOne button {
    border-top: 1px solid $grass-green;
  }
  #aboutAccordionHeadingSeven button {
    border-bottom: 1px solid $orange-btn;
  }

  #resourcepageAccordionHeadingSix button {
    border-bottom: 1px solid $grass-green;
  }
  .accordion-body {
    .accordion-content {
      border-left: 2px solid $orange-btn;
      padding: 5px 0px 5px 15px;
      a {
        color: $green;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &.green {
        border-left-color: $green-btn;
      }
      &.purple {
        border-left-color: $purple-btn;
      }
      &.orange {
        border-left-color: $orange-btn;
      }
      &.pink {
        border-left-color: $pink-btn;
      }
      &.dark-teal {
        border-left-color: $dark-teal;
      }
      &.grass-green {
        border-left-color: $grass-green;
      }
    }
  }
}

// Main subtopic page article heading
.article-wrapper {
  .heading {
    display: flex;
    align-items: center;
    h2 {
      margin-bottom: 0;
    }
    span {
      margin-left: auto;
      white-space: nowrap;
    }
  }
}

// Homepage Carousel
.carousel-wrapper2 {
  .num {
    font-size: 4rem;
    margin-bottom: 0;
  }
  .min {
    font-size: 1.7rem;
    margin-bottom: 0;
  }

  .slider-content-box {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .slider-content-home {
    width: 70%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .my-slide-component-home {
    height: 450px;
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .my-slide-component-home {
      height: 600px;
    }
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) {
  }
}

//About page Carousel
.carousel-wrapper1 {
  .react-stacked-center-carousel {
    margin: 0 auto;
  }
  .slider-img {
    margin: 0 auto 15px;
    width: 150px;
  }
  .slide-about {
    // width: 80% !important;
    // height: 1000px;
    display: flex;
    flex-direction: column;
    align-self: center;
    a.pmw-btn {
      width: fit-content;
      margin: 0 auto;
    }
  }
  .slider-content-about {
    .title {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    .desc {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .my-slide-component-about {
    height: 550px;
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .my-slide-component-about {
      height: 650px;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .my-slide-component-about {
      height: 700px;
    }
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) {
  }
}

//Tips box
.tips-wrapper {
  color: $navy;
  padding-left: 15px;
  padding-right: 15px;
  a {
    color: $green;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .heading {
    display: flex;
    align-items: center;
    h2 {
      margin-bottom: 0;
    }
    span {
      margin-left: auto;
      white-space: nowrap;
    }
  }
  .num-box {
    font-size: 1.75rem;
    font-weight: 600;
    text-align: right;
  }
  .txt-box {
    border-left: 1px solid $navy;
  }
}

//Button
.pmw-btn {
  text-decoration: none;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 600;
  border-radius: 8px;
  padding: 10px 20px;

  &.green1 {
    color: white;
    background-color: $green;
    border: $green 2px solid;
    &:hover {
      opacity: 0.75;
    }
  }
  &.green2 {
    color: white;
    background-color: $green-btn;
    border: $green-btn 2px solid;
    &:hover {
      opacity: 0.75;
    }
  }
  &.purple {
    color: white;
    background-color: $purple-btn;
    border: $purple-btn 2px solid;
    &:hover {
      opacity: 0.75;
    }
  }
  &.orange {
    color: white;
    background-color: $orange-btn;
    border: $orange-btn 2px solid;
    &:hover {
      opacity: 0.75;
    }
  }
  &.pink {
    color: white;
    background-color: $pink-btn;
    border: $pink-btn 2px solid;
    &:hover {
      opacity: 0.75;
    }
  }
  &.dark-teal {
    color: white;
    background-color: $dark-teal;
    border: $dark-teal 2px solid;
    &:hover {
      opacity: 0.75;
    }
  }
  &.white {
    color: $navy;
    background-color: white;
    border: $dark-teal 2px solid;
    &:hover {
      opacity: 0.75;
      color: white;
      background-color: $dark-teal;
    }
    &.active {
      color: white;
      background-color: $dark-teal;
    }
  }
}

.carousel-btn {
  background-color: transparent;
  border-radius: 12px;
  height: 44px;
  width: 44px;
  border: none;
  i {
    font-size: 25px;
  }
}

@import "../../assets/sass/_color.scss";

.pmw-footer {
  background-color: $orange-btn;
  color: white;
  text-align: center;
  p,
  a {
    font-size: 1.125rem;
  }
  a {
    text-decoration: none;
    color: white;
    &:hover {
      opacity: 0.7;
    }
  }
  a.number1 {
    font-size: 1.75rem;
    font-weight: 600;
  }
  a.number2 {
    font-size: 1.125rem;
    position: relative;
    top: -5px;
  }
  .pmw-footer-logo {
    width: 80px;
  }
}

@import "../../assets/sass/_color.scss";

.progress-bar {
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 6px;
    width: 100%;
    z-index: 1500;
  }
  height: 100%;
  width: 0;
  background-color: $grass-green;
  transition: width 10ms linear;
  // border-bottom: 0.0625rem white solid;
}

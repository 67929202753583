.float {
  animation: float 3s infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.rotate {
  animation: rotate 4s infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  0% {
    transform: rotate(-5deg);
  }
  25% {
    transform: rotate(7deg)
  }
  50% {
    transform: rotate(-2deg)
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

.pulse {
  animation: pulse 4s infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.shimmer {
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

// Random delay between 0 and 1500 ms
@for $i from 1 through 10 {
  $delay: random(1500);

  .delay-random-#{$i} {
    animation-delay: $delay + ms;
    transition-delay: $delay + ms;
  }
}
